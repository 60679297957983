import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello World from MODAL!");
  }

  close() {
    this.element.remove();
  }
}
