import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

import Notification from "@stimulus-components/notification";

const application = Application.start();

application.debug = process.env.NODE_ENV === "development";
application.warnings = true;
window.Stimulus = application;

application.register("notification", Notification);
try {
  const controllers = import.meta.glob("~/controllers/**/*_controller.js", {
    eager: true,
  });
  registerControllers(application, controllers);
} catch (error) {
  console.error("Error registering Stimulus controllers:", error);
}

// // Setting the timezone before stimulus is loaded
// setTimezoneCookie("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone)
// function setTimezoneCookie (name, value) {
//   const expires = new Date()
//   expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)
//   document.cookie = `${name}=${value};expires=${expires.toUTCString()};SameSite=Lax`
// }
